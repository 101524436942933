import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Text,
  Divider,
  Button,
  Modal,
  Input,
  Dropdown,
} from "samba-ui";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import shortId from "short-uuid";
import { prepareCampaigns } from "../reducers/campaigns";
import { preparePublishers } from "../reducers/publishers";
import { preparePartners } from "../reducers/partners";
import getQueryString from "../utils/getQueryString";
import PixelGenerationWarningModal from "./PixelGenerationWarningModal";
import CreatePublisherAdServer from "./CreatePublisherAdServer";

// TODO: read from env variables REACT_APP_PIXEL_BASE_URL, etc..
const baseURL = "https://pixel.mtrcs.samba.tv/";
const apiVersion = "v2/";
const reportType = "vtr";
const action = "impression";

const StyledInput = styled(Input)`
  width: 250px;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
`;

const StyledBox = styled(Box)`
  width: 250px;
`;

const GeneratePixelURLModal = ({
  isOpen,
  onClose,
  onSubmit,
  adServers,
  adParams,
  partners,
  campaigns,
  publishers,
  allPublishers,
}) => {
  const [urls, setUrls] = useState({});
  const [partner, setPartner] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [publisher, setPublisher] = useState({});
  const [isCustomBreakout, setIsCustomBreakout] = useState(false);
  const [startCustomBreakout, setStartCustomBreakout] = useState(1);
  const [endCustomBreakout, setEndCustomBreakout] = useState(2);
  const [customBreakoutError, setCustomBrekoutError] = useState(null);
  const [warningData, setWarningData] = useState({});
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [selectedPublisherWithAds, setSelectedPublisherWithAds] = useState({});
  const [showNewPubWithAd, setShowNewPubWithAd] = useState(true);
  const [partnerError, setPartnerError] = useState(null);
  const [publisherError, setPublisherError] = useState(null);
  const [campaignError, setCampaignError] = useState(null);

  const isDisabled =
    partnerError ||
    publisherError ||
    campaignError ||
    !Object.keys(urls).length ||
    !partner ||
    !campaign ||
    !publisher.value ||
    !publisher.selectedAdServer;

  const dropdownOption = Object.keys(adServers).map((key) => {
    const adServer = adServers[key];
    return { label: adServer.name, value: key };
  });

  useEffect(() => {
    const getUrl = (publisher) => {
      return (
        `${baseURL}${apiVersion}${reportType}/${
          partner ? partner.label : "<partner>"
        }/${campaign ? campaign.label : "<campaign>"}/${
          Object.keys(publisher).length ? publisher.label : "<publisher>"
        }${
          publisher.selectedAdServer
            ? publisher.selectedAdServer.label
            : "<ad_server>"
        }/${action}${
          publisher.queryString ? `?${publisher.queryString}` : ""
        }` +
        "&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_350}&gdpr_pd=${GDPR_PD}&ccpa=${CCPA}&us_privacy=${US_PRIVACY}"
      );
    };

    let urls = Object.keys(selectedPublisherWithAds).reduce((acc, key) => {
      const pub = selectedPublisherWithAds[key];
      const { label, selectedAdServer } = pub;
      acc[`${label}-${selectedAdServer.label}`] = getUrl(pub);
      return acc;
    }, {});

    if (publisher && Object.keys(publisher).length) {
      const { label, selectedAdServer } = publisher;
      if (selectedAdServer) {
        urls[`${label}-${selectedAdServer.label}`] = getUrl(publisher);
      }
    }

    setUrls(urls);
  }, [campaign, partner, publisher, selectedPublisherWithAds]);

  const onSelectAdServer = (selected, publisher) => {
    publisher.selectedAdServer = selected;
    publisher.queryString = getQueryString(adServers[selected.value]);
    setPublisher({ ...publisher });
  };

  const getAdOptions = (publisher) => {
    const selectedPublisher = allPublishers[publisher];

    return selectedPublisher && selectedPublisher.linkedAdServers.length
      ? selectedPublisher.linkedAdServers.map((s) => ({
          label: s.name,
          value: s.id,
        }))
      : dropdownOption;
  };

  const onPublisherSelect = (selected) => {
    let newSelected = null;
    if (selected) {
      newSelected = { ...selected };
      newSelected.dropdownOptions = getAdOptions(selected.value);
      newSelected.selectedAdServer = null;
    }
    setPublisher({ ...newSelected });
  };

  const generate = () => {
    let bodies = [];
    const copySelectedPublisherWithAds = {
      ...selectedPublisherWithAds,
      [publisher.label]: publisher,
    };

    Object.keys(copySelectedPublisherWithAds).forEach((key) => {
      const pub = copySelectedPublisherWithAds[key];
      const { label: pubLabel, selectedAdServer } = pub;
      let body = {
        url: urls[
          `${pubLabel}-${selectedAdServer ? selectedAdServer.label : ""}`
        ],
        partner_id: partner.value,
        campaign_id: campaign.value,
        publisher_id: pub.value,
        adserver_id: pub.selectedAdServer.value,
      };

      if (isCustomBreakout) {
        body = {
          ...body,
          start_break_out: startCustomBreakout,
          end_break_out: endCustomBreakout,
        };
      }
      bodies.push(body);
    });

    onSubmit(bodies);

    clearState();
  };

  const onClickSubmit = () => {
    if (isCustomBreakout && endCustomBreakout < startCustomBreakout) {
      setCustomBrekoutError("End number can not be smaller than start number");
      return;
    }

    if (
      !partner ||
      !campaign ||
      !publisher.value ||
      !publisher.selectedAdServer
    ) {
      return;
    }

    let _warningData = {};
    if (!parseInt(partner.value)) {
      _warningData.partner = `${partner.value}`;
    }
    if (!parseInt(campaign.value)) {
      _warningData.campaign = `${campaign.value}`;
    }
    let warningPubs = [];

    Object.keys(selectedPublisherWithAds).forEach((key) => {
      const pub = selectedPublisherWithAds[key];
      if (!parseInt(pub.value)) {
        warningPubs.push(pub.value);
      }
    });

    if (publisher && publisher.label && !parseInt(publisher.value)) {
      warningPubs.push(publisher.value);
    }

    if (warningPubs.length) {
      _warningData.publisher = warningPubs.join(", ");
    }

    if (Object.keys(_warningData).length) {
      setWarningModalOpen(true);
      setWarningData(_warningData);
      return;
    }

    generate();
  };

  const createNewPublisher = () => {
    const copyPublisher = { ...publisher };

    setSelectedPublisherWithAds({
      ...selectedPublisherWithAds,
      [`${copyPublisher.label}-${shortId.generate()}`]: copyPublisher,
    });
    setShowNewPubWithAd(true);
    setPublisher({});
  };

  const clearState = () => {
    setUrls({});
    setCampaign(null);
    setPartner(null);
    setPublisher({});
    setIsCustomBreakout(false);
    setCustomBrekoutError(null);
    setPartnerError(null);
    setPublisherError(null);
    setCampaignError(null);
    setStartCustomBreakout(1);
    setEndCustomBreakout(2);
    setWarningData({});
    setWarningModalOpen(false);
    setSelectedPublisherWithAds({});
    setShowNewPubWithAd(true);
  };

  const deletePublisher = (key) => {
    const copySelectedPublisherWithAds = { ...selectedPublisherWithAds };
    delete copySelectedPublisherWithAds[key];
    setSelectedPublisherWithAds(copySelectedPublisherWithAds);
  };
  return (
    <Box>
      <Modal
        width={824}
        isOpen={isOpen}
        onRequestClose={() => {
          clearState();
          onClose();
        }}
      >
        <Modal.Header borderColor="#d6d6d6 !important">
          <Text>Generate new pixel url</Text>
        </Modal.Header>
        <Modal.Content
          maxHeight={600}
          minHeight={400}
          style={{ overflow: "auto" }}
        >
          <Box mr={"70px"}>
            <Flex alignItems="center" justifyContent="space-between" mt={2}>
              <Text>Partner:</Text>
              {partner && (
                <StyledBox>
                  <StyledInput disabled value={partner.notes || ""} />
                </StyledBox>
              )}
              <StyledBox>
                <CreatableSelect
                  isClearable
                  menuHeight="150px"
                  options={partners}
                  selected={partner}
                  onChange={(e) => {
                    const { value } = e;
                    if (!value || value.match(/^[0-9a-z]+$/)) {
                      setPartnerError(null);
                    } else {
                      setPartnerError(
                        "Partner can only include lowercase alpha numeric values"
                      );
                    }

                    setPartner(e);
                  }}
                  maxHeight={300}
                />
              </StyledBox>
            </Flex>

            <Flex alignItems="center" justifyContent="space-between" mt={2}>
              <Text>Campaign:</Text>
              <StyledBox>
                <CreatableSelect
                  isClearable
                  menuHeight="150px"
                  options={campaigns}
                  selected={campaign}
                  onChange={(e) => {
                    const { value } = e;
                    if (!value || value.match(/^[0-9a-z]+$/)) {
                      setCampaignError(null);
                    } else {
                      setCampaignError(
                        "Campaign can only include lowercase alpha numeric values"
                      );
                    }
                    setCampaign(e);
                  }}
                  maxHeight={300}
                />
              </StyledBox>
            </Flex>
          </Box>
          {Object.keys(selectedPublisherWithAds).map((k) => {
            return (
              <Box
                key={k}
                mr={4}
                style={{ position: "relative" }}
                borderBottom={"1px solid #d6d6d6"}
              >
                <Box mb={2}>
                  <Flex alignItems="center" mt={2}>
                    <Text mr={"74px"}>Publisher:</Text>
                    {selectedPublisherWithAds[k] && (
                      <StyledBox mr={"87px"}>
                        <StyledInput
                          disabled
                          value={selectedPublisherWithAds[k].notes || ""}
                        />
                      </StyledBox>
                    )}
                    <StyledBox>
                      <CreatableSelect
                        isClearable
                        name="publisher"
                        id="publisher"
                        menuHeight="150px"
                        options={publishers}
                        value={selectedPublisherWithAds[k]}
                        onChange={(e) => {
                          const { value } = e;
                          if (!value || value.match(/^[0-9a-z]+$/)) {
                            setPublisherError(null);
                          } else {
                            setPublisherError(
                              "Publisher can only include lowercase alpha numeric values"
                            );
                          }
                          onPublisherSelect(e);
                        }}
                        maxHeight={300}
                      />
                    </StyledBox>
                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Text>Ad Server:</Text>
                    <StyledBox mr={"44px"}>
                      <Dropdown
                        menuHeight="150px"
                        options={selectedPublisherWithAds[k].dropdownOptions}
                        selected={selectedPublisherWithAds[k].selectedAdServer}
                        onSelect={(s) =>
                          onSelectAdServer(s, selectedPublisherWithAds[k])
                        }
                        maxHeight={300}
                      />
                    </StyledBox>
                  </Flex>
                </Box>
                {showNewPubWithAd ? (
                  <Button
                    variant="destructive"
                    onClick={() => deletePublisher(k)}
                    style={{
                      position: "absolute",
                      top: "22px",
                      right: "-22px",
                    }}
                  >
                    X
                  </Button>
                ) : (
                  <Button
                    variant="primaryBordered"
                    onClick={() => setShowNewPubWithAd(true)}
                    style={{
                      position: "absolute",
                      top: "22px",
                      right: "-22px",
                    }}
                  >
                    +
                  </Button>
                )}
              </Box>
            );
          })}
          {showNewPubWithAd && (
            <CreatePublisherAdServer
              createNewPublisher={createNewPublisher}
              publisher={publisher}
              publishers={publishers}
              onSelectAdServer={onSelectAdServer}
              onPublisherSelect={onPublisherSelect}
              setShowNewPubWithAd={setShowNewPubWithAd}
              selectedPublisherWithAds={selectedPublisherWithAds}
            />
          )}

          <Divider />
          {partnerError ? (
            <Box mb={1}>
              <Text fontSize={0} fontWeight={400} color="palette.red.500">
                {partnerError}
              </Text>
            </Box>
          ) : null}
          {campaignError ? (
            <Box mb={1}>
              <Text fontSize={0} fontWeight={400} color="palette.red.500">
                {campaignError}
              </Text>
            </Box>
          ) : null}
          {publisherError ? (
            <Box mb={1}>
              <Text fontSize={0} fontWeight={400} color="palette.red.500">
                {publisherError}
              </Text>
            </Box>
          ) : null}
          <Flex alignItems="center" justifyContent="space-between" mt={2}>
            <Text>Custom breakout</Text>
            <Input
              style={{ width: "auto", marginRight: "12px" }}
              type="checkbox"
              checked={isCustomBreakout}
              onChange={() => setIsCustomBreakout(!isCustomBreakout)}
            />
          </Flex>

          {isCustomBreakout && (
            <Box>
              <Flex alignItems="center" justifyContent="space-between" mt={2}>
                <Text>Enter start and end numbers</Text>
                <Box>
                  <Input
                    type="number"
                    style={{ width: "144px", marginRight: "12px" }}
                    min={1}
                    value={startCustomBreakout}
                    onChange={(e) => {
                      setStartCustomBreakout(e.target.value.trim());
                      setCustomBrekoutError(null);
                    }}
                  />
                  <Input
                    type="number"
                    style={{ width: "144px" }}
                    min={2}
                    value={endCustomBreakout}
                    onChange={(e) => {
                      setEndCustomBreakout(e.target.value.trim());
                      setCustomBrekoutError(null);
                    }}
                  />
                </Box>
              </Flex>
              <Box>
                <Text>{customBreakoutError}</Text>
              </Box>
            </Box>
          )}

          <Box style={{ maxHeight: "200px", overflow: "auto" }}>
            {Object.keys(urls).map((pub) => {
              return (
                <Box
                  key={pub}
                  p={3}
                  my={3}
                  border="1"
                  borderColor="#ececec"
                  borderRadius={4}
                >
                  <Text>{urls[pub]}</Text>
                </Box>
              );
            })}
          </Box>
        </Modal.Content>
        <Modal.Actions
          justifyContent="flex-end"
          borderColor="#d6d6d6 !important"
        >
          <Flex>
            <Box mr={3}>
              <Button
                variant="secondary"
                onClick={() => {
                  clearState();
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="primaryBordered"
              onClick={onClickSubmit}
              disabled={isDisabled}
            >
              Generate
            </Button>
          </Flex>
        </Modal.Actions>
      </Modal>
      {warningModalOpen && (
        <PixelGenerationWarningModal
          isOpen={warningModalOpen}
          onSubmit={generate}
          onClose={() => {
            setWarningData({});
            setWarningModalOpen(false);
          }}
          data={warningData}
        />
      )}
    </Box>
  );
};

GeneratePixelURLModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allPublishers: PropTypes.object.isRequired,
  adServers: PropTypes.object.isRequired,
  adParams: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  publishers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  adServers: state.adServers.data,
  adParams: state.adParams.data,
  allPublishers: state.publishers.data,
  partners: preparePartners(state.partners.data),
  campaigns: prepareCampaigns(state.campaigns.data),
  publishers: preparePublishers(state.publishers.data),
});

export default connect(mapStateToProps, null)(GeneratePixelURLModal);
