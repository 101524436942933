const LOAD_AD_SERVERS = {
  BEGIN: "LOAD_AD_SERVERS_BEGIN",
  SUCCESS: "LOAD_AD_SERVERS_SUCCESS",
  FAILURE: "LOAD_AD_SERVERS_FAILURE"
};

const CREATE_AD_SERVER = {
  BEGIN: "CREATE_AD_SERVER_BEGIN",
  SUCCESS: "CREATE_AD_SERVER_SUCCESS",
  FAILURE: "CREATE_AD_SERVER_FAILURE"
};

const DELETE_AD_SERVER = {
  BEGIN: "DELETE_AD_SERVER_BEGIN",
  SUCCESS: "DELETE_AD_SERVER_SUCCESS",
  FAILURE: "DELETE_AD_SERVER_FAILURE"
};

const UPDATE_AD_SERVER = {
  BEGIN: "UPDATE_AD_SERVER_BEGIN",
  SUCCESS: "UPDATE_AD_SERVER_SUCCESS",
  FAILURE: "UPDATE_AD_SERVER_FAILURE"
};

const initialAdServers = { data: {}, error: null, message: null };

export default function reducer(state = initialAdServers, action = {}) {
  switch (action.type) {
    case LOAD_AD_SERVERS.SUCCESS: {
      // convert to byId
      const data = action.payload.reduce((acc, server) => {
        server["dropdownOptions"] = server.newParams.map(p => {
          const { label, parameter } = p.param;
          const { value } = p;
          return { label, value, parameter };
        });
        acc[server.id] = server;
        return acc;
      }, {});

      return { data };
    }
    case UPDATE_AD_SERVER.SUCCESS:
    case CREATE_AD_SERVER.SUCCESS: {
      action.payload["dropdownOptions"] = action.payload.newParams.map(p => {
        const { label, parameter } = p.param;
        const { value } = p;
        return { label, value, parameter };
      });

      // state.data
      state.data = { ...state.data, [action.payload.id]: action.payload };
      return {
        data: state.data,
        error: null,
        message: `${action.payload.name} is successfully added!`
      };
    }
    case DELETE_AD_SERVER.SUCCESS: {
      delete state.data[action.payload];
      return {
        data: { ...state.data },
        message: `Successfully deleted!`
      };
    }

    default:
      return state;
  }
}

export const getAdServers = token => {
  return {
    types: [
      LOAD_AD_SERVERS.BEGIN,
      LOAD_AD_SERVERS.SUCCESS,
      LOAD_AD_SERVERS.FAILURE
    ],
    api: api => api.getAdServers(token)
  };
};

export const updateAddServer = body => {
  return {
    type: UPDATE_AD_SERVER,
    payload: body
  };
};

export const createAddServer = body => {
  return {
    types: [
      CREATE_AD_SERVER.BEGIN,
      CREATE_AD_SERVER.SUCCESS,
      CREATE_AD_SERVER.FAILURE
    ],
    api: api => api.createAddServer(body)
  };
};

export const deleteAdServer = id => {
  return {
    types: [
      DELETE_AD_SERVER.BEGIN,
      DELETE_AD_SERVER.SUCCESS,
      DELETE_AD_SERVER.FAILURE
    ],
    api: api => api.deleteAdServer(id)
  };
};

export const updateAdServer = (id, body) => {
  return {
    types: [
      UPDATE_AD_SERVER.BEGIN,
      UPDATE_AD_SERVER.SUCCESS,
      UPDATE_AD_SERVER.FAILURE
    ],
    api: api => api.updateAdServer(id, body)
  };
};
