import { useState } from "react";

export default function useActiveRoute(initialRoute) {
  const [activeRoute, setActiveRoute] = useState(initialRoute);

  const isActive = (path, match, location) => {
    const isPathActive = !!(
      match ||
      (path.slice(1) && location.pathname.indexOf(path) > -1)
    );

    if (isPathActive) {
      setActiveRoute(path);
    }
  };

  return [activeRoute, isActive];
}
