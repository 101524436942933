import * as API from "../api";
import ApiError from "../utils/ApiError";

const status = response => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(new ApiError(response.statusText, response.status));
  }
};

const json = response => {
  return response.json();
};

const data = response => response.data;

/*
 * API middleware
 * This middleware passes API functionalities to every action
 */
const api = () => next => ({ api, ...action } = {}) => {
  if (typeof api === "function") {
    return next({
      ...action,
      types: action.types,
      promise: () =>
        api(API)
          .then(status)
          .then(json)
          .then(data)
    });
  }

  return next(action);
};

export default api;
