import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Heading,
  Box,
  List,
  Text,
  Button,
  Input
} from "samba-ui";
import CreateNewParamModal from "./CreateNewParamModal";
import {
  getAdParameters,
  deleteAdParameter,
  updateAdParameter,
  createAdParameter
} from "../reducers/adParams";
import AreYouSureModal from "./AreYouSureModal";

const AdParams = ({
  adParams,
  getAdParameters,
  deleteAdParameter,
  updateAdParameter,
  createAdParameter
}) => {
  useEffect(() => {
    getAdParameters();
  }, [getAdParameters]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAdParam, setSelectedAdParam] = useState({});
  const [editMode, setEditMode] = useState(false);

  adParams = Object.keys(adParams).reduce((acc, id) => {
    const { label, parameter } = adParams[id];
    if (
      label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
      parameter.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    ) {
      acc[id] = adParams[id];
    }
    return acc;
  }, {});

  const createNewParam = (label, parameter) => {
    // addNewParam({ label, param });
    createAdParameter({ label, parameter });
    setIsOpen(false);
  };

  const onUpdateAdParameter = () => {
    const { label, parameter, id } = selectedAdParam;
    updateAdParameter(id, { label: label.trim(), parameter });
    setEditMode(false);
    setSelectedAdParam({});
  };
  const onDeleteAdParameter = () => {
    deleteAdParameter(selectedAdParam.id);
    setSelectedAdParam({});
    setIsDeleteModalOpen(false);
  };

  const onNewAdChange = (value, type) => {
    const newValues = { ...selectedAdParam };
    newValues[type] = value;
    setSelectedAdParam(newValues);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              Ad Parameters
            </Heading>
          </Flex>
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center">
              <Box width={1 / 3}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Label
                </Text>
              </Box>
              <Box width={1 / 3}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Parameter
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!Object.keys(adParams).length && (
            <List.Empty>List is empty!</List.Empty>
          )}
          {Object.keys(adParams).map(id => {
            return (
              <List.Item key={id} borderColor="#d6d6d6 !important">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box width={1 / 3}>
                    {editMode && selectedAdParam.id === parseInt(id) ? (
                      <Input
                        value={selectedAdParam.label}
                        onChange={e => onNewAdChange(e.target.value, "label")}
                      />
                    ) : (
                      <Text fontSize={2} fontWeight={400}>
                        {adParams[id]["label"]}
                      </Text>
                    )}
                  </Box>
                  <Box width={1 / 3}>
                    {editMode && selectedAdParam.id === parseInt(id) ? (
                      <Input
                        value={selectedAdParam.parameter}
                        onChange={e =>
                          onNewAdChange(e.target.value.trim(), "parameter")
                        }
                      />
                    ) : (
                      <Text fontSize={2} fontWeight={400}>
                        {adParams[id]["parameter"]}
                      </Text>
                    )}
                  </Box>
                  {editMode && selectedAdParam.id === parseInt(id) ? (
                    <Flex
                      width={1 / 3}
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Button
                        width="80px"
                        variant="secondary"
                        onClick={() => {
                          setSelectedAdParam({});
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        width="80px"
                        variant="primaryBordered"
                        onClick={onUpdateAdParameter}
                      >
                        Save
                      </Button>
                    </Flex>
                  ) : (
                    <Flex
                      width={1 / 3}
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Button
                        width="80px"
                        variant="primaryBordered"
                        onClick={() => {
                          setSelectedAdParam(adParams[id]);
                          setEditMode(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        width="80px"
                        variant="destructiveBordered"
                        onClick={() => {
                          setSelectedAdParam(adParams[id]);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </List.Item>
            );
          })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Ad Parameter
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(!isOpen)}>
            Create New Parameter
          </Button>
        </Box>
      </Flex>
      <CreateNewParamModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={createNewParam}
      />
      <AreYouSureModal
        title="Delete Ad Parameter"
        content={`Are you sure you want to delete the ad parameter: ${selectedAdParam.label}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={onDeleteAdParameter}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  adParams: state.adParams.data
});

const mapDispatchToProps = dispatch => ({
  getAdParameters: () => dispatch(getAdParameters()),
  deleteAdParameter: id => dispatch(deleteAdParameter(id)),
  updateAdParameter: (id, body) => dispatch(updateAdParameter(id, body)),
  createAdParameter: body => dispatch(createAdParameter(body))
});

AdParams.propTypes = {
  adParams: PropTypes.object.isRequired,
  getAdParameters: PropTypes.func.isRequired,
  deleteAdParameter: PropTypes.func.isRequired,
  updateAdParameter: PropTypes.func.isRequired,
  createAdParameter: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdParams);
