import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Heading,
  Box,
  List,
  Text,
  Button,
  Input,
  Message
} from "samba-ui";
import SinglePartner from "./SinglePartner";

import {
  getPartners,
  createPartner,
  clearPartnerErrors,
  deletePartner,
  updatePartner
} from "../reducers/partners";
import CreateNewPartnerModal from "./CreateNewPartnerModal";

const Partners = ({
  partners,
  pixels,
  createPartner,
  getPartners,
  error,
  clearPartnerErrors,
  message,
  deletePartner,
  updatePartner
}) => {
  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    if (error || message) {
      // clear Error Message
      setTimeout(clearPartnerErrors, 2000);
    }
  }, [clearPartnerErrors, error, message]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  partners = Object.values(partners).filter(
    c => c.partnerName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );

  const createNewPartner = (name, notes) => {
    createPartner({ name, notes });
    setIsOpen(false);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              Partners
            </Heading>
          </Flex>
          {(message || error) && (
            <Message mb={3} variant={error ? "alert" : "success"}>
              {message || error}
            </Message>
          )}
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center">
              <Box width={2 / 8}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Name
                </Text>
              </Box>
              <Box width={2 / 8}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Notes
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!partners.length && <List.Empty>List is empty!</List.Empty>}
          {partners.map(partner => {
            return (
              <SinglePartner
                key={partner.name}
                partner={partner}
                updatePartner={updatePartner}
                deletePartner={deletePartner}
              />
            );
          })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Partner
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(!isOpen)}>
            Create New Partner
          </Button>
        </Box>
      </Flex>
      <CreateNewPartnerModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={createNewPartner}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  partners: state.partners.data,
  pixels: state.pixels.data,
  error: state.partners.error,
  message: state.partners.message
});

const mapDispatchToProps = dispatch => ({
  getPartners: () => dispatch(getPartners()),
  deletePartner: id => dispatch(deletePartner(id)),
  createPartner: body => dispatch(createPartner(body)),
  updatePartner: (id, body) => dispatch(updatePartner(id, body)),
  clearPartnerErrors: () => dispatch(clearPartnerErrors())
});

Partners.propTypes = {
  partners: PropTypes.object.isRequired,
  pixels: PropTypes.object.isRequired,
  createPartner: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  deletePartner: PropTypes.func.isRequired,
  updatePartner: PropTypes.func.isRequired,
  clearPartnerErrors: PropTypes.func.isRequired,
  error: PropTypes.string,
  message: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners);
