import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, List, Text, Button, Dropdown } from "samba-ui";
import NameInput from "./NameInput";
import AreYouSureModal from "./AreYouSureModal";

const DisabledBox = styled(Box)`
  pointer-events: none;
  width: 350px;
`;

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
  border-radius: 4px;
  border-color: rgba(207, 214, 230, 1);
  width: 100%;
`;

const SinglePublisher = ({
  publisher,
  updatePublisher,
  adServers,
  deletePublisher
}) => {
  const [nameError, setNameError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newPublisherName, setNewPublisherName] = useState("");
  const [newAdServers, setNewAdServers] = useState(null);
  const dropdownOptions = Object.keys(adServers).map(key => {
    const adServer = adServers[key];
    return { label: adServer.name, value: key };
  });
  const [newNotes, setNewNotes] = useState("");

  const onDeletePublisher = () => {
    setIsDeleteModalOpen(false);
  };

  let { publisherName: name, linkedAdServers, notes } = publisher;

  linkedAdServers = linkedAdServers.map(s => ({
    label: s.name,
    value: `${s.id}`
  }));

  return (
    <List.Item key={name} borderColor="#d6d6d6 !important">
      <Flex alignItems="center" justifyContent="space-between">
        <Box width={6 / 8}>
          {editMode ? (
            <Flex alignItems="center">
              <NameInput
                value={newPublisherName}
                setValue={setNewPublisherName}
                error={nameError}
                setError={setNameError}
                style={{ width: 150, marginRight: 24 }}
              />
              <Box width={350}>
                <Dropdown
                  selected={newAdServers}
                  isMulti
                  options={dropdownOptions}
                  onSelect={setNewAdServers}
                />
              </Box>
              <Box ml={4}>
                <StyledTextArea
                  resize="none"
                  disabled={!editMode}
                  value={editMode ? newNotes : notes}
                  onChange={e => setNewNotes(e.target.value)}
                />
              </Box>
            </Flex>
          ) : (
            <Flex alignItems="center">
              <Box width={150} mr={4}>
                <Text fontSize={2} fontWeight={400}>
                  {name}
                </Text>
              </Box>
              <DisabledBox>
                <Dropdown selected={linkedAdServers} isMulti />
              </DisabledBox>
              <Box ml={4}>
                <StyledTextArea
                  resize="none"
                  disabled={!editMode}
                  value={editMode ? newNotes : notes}
                  onChange={e => setNewNotes(e.target.value)}
                />
              </Box>
            </Flex>
          )}
        </Box>
        <Flex width={2 / 8} alignItems="center" justifyContent="flex-end">
          <Box>
            {!editMode ? (
              <Box mr={2}>
                <Button
                  variant="primaryBordered"
                  onClick={() => {
                    setNewPublisherName(name.trim());
                    setNewAdServers(linkedAdServers);
                    setEditMode(true);
                    setNewNotes(notes.trim());
                  }}
                >
                  Update
                </Button>
              </Box>
            ) : (
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEditMode(false);
                    setNewNotes("");
                  }}
                >
                  Cancel
                </Button>
                <Box mx={2}>
                  <Button
                    variant="primaryBordered"
                    disabled={nameError}
                    onClick={() => {
                      setEditMode(false);
                      updatePublisher(publisher.id, {
                        name: newPublisherName,
                        notes: newNotes,
                        linked_ad_servers: newAdServers.map(s => s.value)
                      });
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Flex>
            )}
          </Box>
          <Box>
            <Button
              variant="destructiveBordered"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </Box>
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <AreYouSureModal
          title="Delete Publisher"
          content={`Are you sure you want to delete the publisher: ${name}`}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onSubmit={onDeletePublisher}
        />
      )}
    </List.Item>
  );
};

export default SinglePublisher;
