import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Spinner,
  Heading,
  Box,
  List,
  Text,
  Button,
  Input,
  IconSvg
} from "samba-ui";
import styled from "styled-components";
import GeneratePixelURLModal from "./GeneratePixelURLModal";
import { createNewPixel, getPixels } from "../reducers/pixels";
import { PreviewIcon } from "./icons";
import DownloadPixels from "./DownloadPixels";
import getQueryString from "../utils/getQueryString";

const baseURL = "https://pixel.mtrcs.samba.tv/";
const apiVersion = "v2/";
const reportType = "vtr";
const action = "impression";

const StyledText = styled(Text)`
  overflow-wrap: break-word;
  word-break: break-all;
`;

const Pixels = ({ pixels, createNewPixel, getPixels, adServers }) => {
  useEffect(() => {
    getPixels();
  }, [getPixels]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [previewMode, setPreviewMode] = useState(false);
  const [selectedPixel, setSelectedPixel] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPixels, setSelectedPixels] = useState({});
  const [disableButton, setDisableButton] = useState(true);

  pixels = Object.keys(pixels).reduce((acc, id) => {
    const {
      partner: { partner_name },
      campaign: { campaign_name },
      publisher: { publisher_name },
      adServer: { name: ad_server_name }
    } = pixels[id];

    // search by keyword
    if (
      partner_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
      campaign_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
      publisher_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
      ad_server_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    ) {
      acc[id] = pixels[id];
    }
    return acc;
  }, {});

  // group pixels
  pixels = Object.keys(pixels).reduce((acc, id) => {
    acc[id] = pixels[id];
    return acc;
  }, {});

  const onCreateNewPixel = bodies => {
    createNewPixel(bodies);
    setIsOpen(false);
  };

  const onPreviewClick = pixel => {
    setSelectedPixel(pixel);
    setPreviewMode(true);
  };

  const onClickSelectAll = e => {
    const { checked } = e.target;

    if (checked) {
      const _selectedPixels = Object.keys(pixels).reduce((acc, p) => {
        acc[p] = true;
        return acc;
      }, {});
      setSelectedPixels(_selectedPixels);
    } else {
      setSelectedPixels({});
    }
    setSelectAll(checked);
    setDisableButton(!checked);
  };

  const prepareUrls = pixel => {
    const {
      partner: { partner_name },
      campaign: { campaign_name },
      publisher: { publisher_name },
      adServer: { name: ad_server_name, id: ad_server_id },
      start_break_out,
      end_break_out,
      url
    } = pixel;

    if (!start_break_out) {
      return (
        <StyledText fontSize={1} fontWeight={400}>
          {url}
        </StyledText>
      );
    }
    let urls = [];
    if (!Object.keys(adServers).length) {
      return (
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Spinner variant="small" color="primary" />
          <Text ml={3}>Preparing urls for custom breakouts</Text>
        </Flex>
      );
    }

    for (var i = start_break_out; i < end_break_out; i++) {
      urls.push(
        `${baseURL}${apiVersion}${reportType}/${partner_name}/${campaign_name}/${publisher_name}${ad_server_name}${i}/${action}?${getQueryString(
          adServers[ad_server_id]
        )}`
      );
    }

    return urls.map(url => (
      <Box>
        <StyledText fontSize={1} fontWeight={400}>
          {url}
        </StyledText>
      </Box>
    ));
  };

  const onSelectPixels = (e, name) => {
    let _selectedPixels = selectedPixels;
    _selectedPixels[name] = e.target.checked;
    setSelectedPixels({ ..._selectedPixels });

    const hasChecked = !!Object.values(_selectedPixels).filter(x => !!x).length;
    setDisableButton(!hasChecked);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              Pixel Generation
            </Heading>
          </Flex>
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center" justifyContent="space-between">
              <Flex width={1 / 16} alignItems="center">
                <Input
                  style={{ width: "auto", marginRight: "12px" }}
                  type="checkbox"
                  checked={selectAll}
                  onChange={onClickSelectAll}
                />
              </Flex>
              <Box width={3 / 16}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Partner
                </Text>
              </Box>
              <Box width={3 / 16}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Campaign
                </Text>
              </Box>
              <Box width={3 / 16}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Publisher
                </Text>
              </Box>
              <Box width={3 / 16}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Ad Server
                </Text>
              </Box>
              <Box width={1 / 16}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  URL
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!Object.keys(pixels).length && (
            <List.Empty>List is empty!</List.Empty>
          )}
          {Object.values(pixels)
            .reverse()
            .map(pixel => {
              const {
                partner: { partner_name },
                campaign: { campaign_name },
                publisher: { publisher_name },
                adServer: { name: ad_server_name },
                id
              } = pixel;
              return (
                <List.Item key={id} borderColor="#d6d6d6 !important">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex width={1 / 16} alignItems="center">
                      <Input
                        style={{ width: "auto", marginRight: "12px" }}
                        type="checkbox"
                        checked={selectedPixels[id]}
                        onChange={e => onSelectPixels(e, id)}
                      />
                    </Flex>
                    <Box width={3 / 16}>
                      <Text fontWeight={400} fontSize={2}>
                        {partner_name}
                      </Text>
                    </Box>
                    <Box width={3 / 16}>
                      <Text fontWeight={400} fontSize={2}>
                        {campaign_name}
                      </Text>
                    </Box>
                    <Box width={3 / 16}>
                      <Text fontWeight={400} fontSize={2}>
                        {publisher_name}
                      </Text>
                    </Box>
                    <Box width={3 / 16}>
                      <Text fontWeight={400} fontSize={2}>
                        {ad_server_name}
                      </Text>
                    </Box>
                    <Box width={1 / 16}>
                      {previewMode && selectedPixel.id === id ? (
                        <IconSvg
                          name="close"
                          color="red"
                          onClick={() => {
                            setSelectedPixel({});
                            setPreviewMode(false);
                          }}
                        />
                      ) : (
                        <PreviewIcon
                          onClick={() => onPreviewClick(pixels[id])}
                        />
                      )}
                    </Box>
                  </Flex>
                  {previewMode && selectedPixel.id === id ? (
                    <Box mt={3} mr={4} maxHeight="150px" overflow="scroll">
                      {prepareUrls(pixels[id])}
                    </Box>
                  ) : null}
                </List.Item>
              );
            })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Pixel
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(!isOpen)}>
            Generate New Pixel
          </Button>
          <DownloadPixels
            disabled={disableButton}
            selectedPixels={selectedPixels}
            adServers={adServers}
          />
        </Box>
      </Flex>
      {isOpen && (
        <GeneratePixelURLModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={onCreateNewPixel}
        />
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  pixels: state.pixels.data,
  adServers: state.adServers.data
});

const mapDispatchToProps = dispatch => ({
  createNewPixel: bodies => dispatch(createNewPixel(bodies)),
  getPixels: () => dispatch(getPixels())
});

Pixels.propTypes = {
  pixels: PropTypes.object.isRequired,
  createNewPixel: PropTypes.func.isRequired,
  getPixels: PropTypes.func.isRequired,
  adServers: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pixels);
