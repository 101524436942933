import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "./components/PageLoader";
import App from "./App";
import UnauthorizedApp from "./UnauthorizedApp";

const AppWrapper = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    return <UnauthorizedApp />;
  }

  return <App />;
};

export default AppWrapper;
