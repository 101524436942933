import React, { useState } from "react";
import { Box, Flex, List, Button, Text } from "samba-ui";
import styled from "styled-components";
import NameInput from "./NameInput";
import AreYouSureModal from "./AreYouSureModal";

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
  border-radius: 4px;
  border-color: rgba(207, 214, 230, 1);
  width: 100%;
`;

const SinglePartner = ({ updatePartner, partner, deletePartner }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [nameError, setNameError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [newPartnerName, setNewPartnerName] = useState("");
  const [newNotes, setNewNotes] = useState("");

  const onDeletePartner = () => {
    deletePartner(partner.id);
    setIsDeleteModalOpen(false);
  };

  return (
    <List.Item borderColor="#d6d6d6 !important">
      <Flex alignItems="center" justifyContent="space-between">
        <Box width={2 / 8}>
          {editMode ? (
            <NameInput
              value={newPartnerName}
              setValue={setNewPartnerName}
              error={nameError}
              setError={setNameError}
              style={{ width: 200, marginRight: 24 }}
            />
          ) : (
            <Text fontSize={2} fontWeight={400}>
              {partner.partnerName}
            </Text>
          )}
        </Box>
        <Box width={2 / 8}>
          <StyledTextArea
            disabled={!editMode}
            value={editMode ? newNotes : partner.notes}
            onChange={e => setNewNotes(e.target.value)}
          />
        </Box>
        <Flex
          width={4 / 8}
          alignItems="center"
          textAlign="right"
          justifyContent="space-around"
        >
          <Box width={5 / 16}>
            {!editMode ? (
              <Button
                variant="primaryBordered"
                onClick={() => {
                  setNewPartnerName(partner.partnerName);
                  setEditMode(true);
                  setNewNotes(partner.notes);
                }}
              >
                Update
              </Button>
            ) : (
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEditMode(false);
                    setNewNotes("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primaryBordered"
                  disabled={nameError}
                  onClick={() => {
                    setEditMode(false);
                    updatePartner(partner.id, {
                      name: newPartnerName.trim(),
                      notes: newNotes.trim()
                    });
                  }}
                >
                  Save
                </Button>
              </Flex>
            )}
          </Box>
          <Box width={1 / 8}>
            <Button
              variant="destructiveBordered"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </Box>
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <AreYouSureModal
          title="Delete Partner"
          content={`Are you sure you want to delete the partner: ${partner.partnerName}`}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onSubmit={onDeletePartner}
        />
      )}
    </List.Item>
  );
};

export default SinglePartner;
