import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown, Box, Flex, Text, Button, Modal } from "samba-ui";
import styled from "styled-components";
import NameInput from "./NameInput";

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
  border-radius: 4px;
  border-color: rgba(207, 214, 230, 1);
  width: 290px;
`;

const CreateNewPublisherModal = ({ isOpen, onClose, onSubmit, adServers }) => {
  const [publisherName, setPublisherName] = useState("");
  const [nameError, setNameError] = useState("");
  const [selectedAdServers, setSelectedAdServers] = useState([]);
  const dropdownOptions = Object.keys(adServers).map(key => {
    const adServer = adServers[key];
    return { label: adServer.name, value: key };
  });
  const [notes, setNotes] = useState("");

  const clearState = () => {
    setPublisherName("");
    setSelectedAdServers([]);
    setNotes("");
  };

  const onCreateClick = () => {
    clearState();
    onSubmit({
      name: publisherName,
      linked_ad_servers: selectedAdServers.map(s => s.value),
      notes
    });
  };

  const disabled = nameError || !publisherName;

  return (
    <Modal width={592} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Create Ad Publisher</Text>
      </Modal.Header>
      <Modal.Content>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Publisher Name</Text>
          <NameInput
            value={publisherName}
            setValue={setPublisherName}
            error={nameError}
            setError={setNameError}
          />
        </Flex>
        <Box mb={3}>
          <Text>Link Ad Servers</Text>
          <Box mt={1} style={{ width: "100%" }}>
            <Dropdown
              menuHeight="100px"
              isMulti
              options={dropdownOptions}
              selected={selectedAdServers}
              onSelect={setSelectedAdServers}
              maxHeight={300}
            />
          </Box>
        </Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Notes</Text>
          <StyledTextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </Flex>
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button
            variant="primaryBordered"
            onClick={onCreateClick}
            disabled={disabled}
          >
            Create
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

CreateNewPublisherModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  adServers: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  adServers: state.adServers.data
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewPublisherModal);
