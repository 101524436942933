import React from "react";
import PropTypes from "prop-types";
import { Box, Input, Text } from "samba-ui";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 300px;
`;
const WrapperBox = styled(Box)`
  position: relative;
`;
const ErrorBox = styled(Box)`
  position: absolute;
  top: 40px;
`;

const NameInput = ({ value, setValue, error, setError, ...props }) => {
  const onInputChange = e => {
    const { value } = e.target;
    setValue(value.trim());

    if (!value || value.match(/^[0-9a-z]+$/)) {
      setError(null);
    } else {
      setError("Name can only include lowercase alpha numeric values");
    }
  };

  return (
    <WrapperBox>
      <StyledInput onChange={onInputChange} value={value} {...props} />
      {error ? (
        <ErrorBox>
          <Text fontSize={0} fontWeight={400} color="palette.red.500">
            {error}
          </Text>
        </ErrorBox>
      ) : null}
    </WrapperBox>
  );
};

NameInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired
};

export default NameInput;
