import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Sidebar as SambaSidebar } from "samba-ui";
import useWindowEvent from "./hooks/useWindowEvent";

import Pages from "./Pages";

export const SidebarWidth = {
  CLOSED: 0,
  COLLAPSED: "56px",
  REGULAR: "240px"
};

const Wrapper = styled(SambaSidebar)`
  position: fixed;
  height: 100%;
  top: 0;
  left: ${p => (p.visible ? 0 : `-${SidebarWidth.REGULAR}`)};
  z-index: 20;
`;

const Sidebar = ({ visible, pages }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [mounted, setMounted] = useState(false);
  const onSidebarMouseEnter = () => setCollapsed(false);
  const onSidebarMouseLeave = () => setCollapsed(true);

  useWindowEvent("click", e => {
    if (e.clientX > parseInt(SidebarWidth.REGULAR, 10)) {
      setCollapsed(true);
    }
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Wrapper
      visible={visible}
      collapsed={mounted && collapsed}
      onMouseEnter={onSidebarMouseEnter}
      onMouseLeave={onSidebarMouseLeave}
    >
      <Pages pages={pages} />
    </Wrapper>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool,
  pages: PropTypes.array
};

Sidebar.defaultProps = {
  visible: true
};

export default Sidebar;
