import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Heading,
  Box,
  List,
  Text,
  Dropdown,
  Button,
  Input,
  Message
} from "samba-ui";
import styled from "styled-components";
import CreateAdServerModal from "./CreateAdServerModal";
import {
  createAddServer,
  getAdServers,
  deleteAdServer,
  updateAdServer
} from "../reducers/adServers";
import AreYouSureModal from "./AreYouSureModal";

const DisabledBox = styled(Box)`
  pointer-events: none;
`;

const AdServers = ({
  adServers,
  adParams,
  createAddServer,
  getAdServers,
  deleteAdServer,
  updateAdServer,
  message,
  error
}) => {
  useEffect(() => {
    getAdServers();
  }, [getAdServers]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAdServer, setSelectedAdServer] = useState({});
  const [updateMode, setUpdateMode] = useState(false);

  adServers = Object.keys(adServers).reduce((acc, id) => {
    const { name } = adServers[id];
    if (name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
      acc[name] = adServers[id];
    }
    return acc;
  }, {});

  const createNewAdServer = body => {
    createAddServer(body);
    setIsOpen(false);
    setSelectedAdServer({});
  };

  const onDeleteAdServer = () => {
    deleteAdServer(selectedAdServer.id);
    setIsDeleteModalOpen(false);
    setSelectedAdServer({});
  };

  const onUpdateAdServer = body => {
    updateAdServer(selectedAdServer.id, body);
    setIsOpen(false);
    setSelectedAdServer({});
    setUpdateMode(false);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              3rd party Ad Servers
            </Heading>
          </Flex>
          {(message || error) && (
            <Message mb={3} variant={error ? "alert" : "success"}>
              {message || error}
            </Message>
          )}
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center">
              <Box width={1 / 4}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Ad Servers
                </Text>
              </Box>
              <Box width={3 / 4}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Query Parameters
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!Object.keys(adServers).length && (
            <List.Empty>List is empty!</List.Empty>
          )}
          {Object.keys(adServers).map((name, index) => {
            const adServer = adServers[name];
            const { dropdownOptions } = adServer;
            return (
              <List.Item
                key={`${name}${index}`}
                borderColor="#d6d6d6 !important"
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Box width={1 / 4}>
                    <Text fontSize={2} fontWeight={400}>
                      {name}
                    </Text>
                  </Box>
                  <DisabledBox width={2 / 4} textAlign="right">
                    <Dropdown isMulti selected={dropdownOptions} />
                  </DisabledBox>
                  <Flex
                    ml={3}
                    width={1 / 4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Button
                      variant="primaryBordered"
                      onClick={() => {
                        setSelectedAdServer(adServer);
                        setUpdateMode(true);
                        setIsOpen(true);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      variant="destructiveBordered"
                      onClick={() => {
                        setSelectedAdServer(adServer);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Flex>
              </List.Item>
            );
          })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Ad Servers
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(true)}>
            Create Ad Server
          </Button>
        </Box>
      </Flex>
      <CreateAdServerModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={updateMode ? onUpdateAdServer : createNewAdServer}
        adParams={adParams}
        adServer={selectedAdServer}
        updateMode={updateMode}
      />
      <AreYouSureModal
        title="Delete Publisher"
        content={`Are you sure you want to delete the ad server: ${selectedAdServer.name}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={onDeleteAdServer}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  adServers: state.adServers.data,
  messages: state.adServers.messages,
  error: state.adServers.error,
  adParams: state.adParams.data
});

const mapDispatchToProps = dispatch => ({
  createAddServer: body => dispatch(createAddServer(body)),
  getAdServers: () => dispatch(getAdServers()),
  deleteAdServer: id => dispatch(deleteAdServer(id)),
  updateAdServer: (id, body) => dispatch(updateAdServer(id, body))
});

AdServers.propTypes = {
  adServers: PropTypes.object.isRequired,
  adParams: PropTypes.object.isRequired,
  createAddServer: PropTypes.func.isRequired,
  deleteAdServer: PropTypes.func.isRequired,
  updateAdServer: PropTypes.func.isRequired,
  getAdServers: PropTypes.func.isRequired,
  error: PropTypes.string,
  message: PropTypes.string
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdServers);
