import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Heading,
  Box,
  List,
  Text,
  Button,
  Input,
  Message
} from "samba-ui";

import {
  getPublishers,
  createPublisher,
  clearPublisherErrors,
  deletePublisher,
  updatePublisher
} from "../reducers/publishers";
import CreateNewPublisherModal from "./CreateNewPublisherModal";

import SinglePublisher from "./SinglePublisher";

const Publishers = ({
  publishers,
  createPublisher,
  getPublishers,
  adServers,
  error,
  clearPublisherErrors,
  message,
  deletePublisher,
  updatePublisher
}) => {
  useEffect(() => {
    getPublishers();
  }, [getPublishers]);

  useEffect(() => {
    if (error || message) {
      // clear Error Message
      setTimeout(clearPublisherErrors, 2000);
    }
  }, [clearPublisherErrors, error, message]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  publishers = Object.values(publishers).filter(
    c => c.publisherName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );

  const createNewPublisher = body => {
    createPublisher(body);
    setIsOpen(false);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              Publishers
            </Heading>
          </Flex>
          {(message || error) && (
            <Message mb={3} variant={error ? "alert" : "success"}>
              {message || error}
            </Message>
          )}
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center">
              <Box width={150} mr={4}>
                <Text fontSize={1} color="secondary" fontWeight={500}>
                  Name
                </Text>
              </Box>
              <Box width={340}>
                <Text fontSize={1} color="secondary" fontWeight={500}>
                  Linked Ad Servers
                </Text>
              </Box>
              <Box ml={5}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Notes
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!publishers.length && <List.Empty>List is empty!</List.Empty>}
          {publishers.map((publisher, index) => {
            return (
              <SinglePublisher
                key={index}
                publisher={publisher}
                adServers={adServers}
                deletePublisher={deletePublisher}
                updatePublisher={updatePublisher}
              />
            );
          })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Publisher
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(!isOpen)}>
            Create New Publisher
          </Button>
        </Box>
      </Flex>
      <CreateNewPublisherModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={createNewPublisher}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  publishers: state.publishers.data,
  error: state.publishers.error,
  message: state.publishers.message,
  adServers: state.adServers.data
});

const mapDispatchToProps = dispatch => ({
  getPublishers: () => dispatch(getPublishers()),
  deletePublisher: id => dispatch(deletePublisher(id)),
  createPublisher: body => dispatch(createPublisher(body)),
  updatePublisher: (id, body) => dispatch(updatePublisher(id, body)),
  clearPublisherErrors: () => dispatch(clearPublisherErrors())
});

Publishers.propTypes = {
  publishers: PropTypes.object.isRequired,
  adServers: PropTypes.object.isRequired,
  createPublisher: PropTypes.func.isRequired,
  getPublishers: PropTypes.func.isRequired,
  deletePublisher: PropTypes.func.isRequired,
  updatePublisher: PropTypes.func.isRequired,
  clearPublisherErrors: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publishers);
