import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box, Button } from "samba-ui";
import { downloadPixel } from "../api/";
import getQueryString from "../utils/getQueryString";

const baseURL = "https://pixel.mtrcs.samba.tv/";
const apiVersion = "v2/";
const reportType = "vtr";
const action = "impression";

const DownloadPixels = ({ pixels, disabled, selectedPixels, adServers }) => {
  const selectedPixelNames = Object.keys(selectedPixels);
  const selectedCount = Object.values(selectedPixels).filter(x => !!x).length;
  const onDownload = async () => {
    await Promise.all(
      selectedPixelNames.map(async (id, index) => {
        if (selectedPixels[id]) {
          const pixel = pixels[id];
          const { url, start_break_out, end_break_out } = pixel;
          const partner = pixel.partner.partner_name;
          const campaign = pixel.campaign.campaign_name;
          const adServer = pixel.adServer.name;
          const publisher = pixel.publisher.publisher_name;
          const fileName = `${partner}-${campaign}-${publisher}-${adServer}-sambatv-pixel.xlsx`;

          let rest = [];
          if (start_break_out && end_break_out) {
            for (var i = start_break_out; i < end_break_out; i++) {
              rest.push(
                `${baseURL}${apiVersion}${reportType}/${partner}/${campaign}/${publisher}${adServer}${i}/${action}?${getQueryString(
                  adServers[pixel.adServer.id]
                )}`
              );
            }
          }
          const response = await downloadPixel({ urls: [url, ...rest] });
          const myBlob = await response.blob().then();
          const _url = window.URL.createObjectURL(myBlob);
          const link = document.createElement("a");
          link.href = _url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
    );
  };

  return (
    <Box mt={3}>
      <Button width={"100%"} disabled={disabled} onClick={onDownload}>
        Download Selected ({selectedCount}) Pixels
      </Button>
    </Box>
  );
};

const mapStateToProps = state => ({
  pixels: state.pixels.data
});

DownloadPixels.propTypes = {
  pixels: PropTypes.object.isRequired,
  selectedPixels: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

DownloadPixels.defaultProps = {
  disabled: false
};

export default connect(mapStateToProps, null)(DownloadPixels);
