import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import api from "./middleware/api";
import promise from "./middleware/promise";
import reducers from "./reducers";

const middleware = [thunkMiddleware, api, promise];

// eslint-disable-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState = {}) =>
  createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

export default configureStore;
