export const CLEAR_PARTNER_MESSAGES = "CLEAR_PARTNER_MESSAGES";
export const ADD_NEW_PARTNER = {
  BEGIN: "ADD_NEW_PARTNER_BEGIN",
  SUCCESS: "ADD_NEW_PARTNER_SUCCESS",
  FAILURE: "ADD_NEW_PARTNER_FAILURE"
};
export const DELETE_PARTNER = {
  BEGIN: "DELETE_PARTNER_BEGIN",
  SUCCESS: "DELETE_PARTNER_SUCCESS",
  FAILURE: "DELETE_PARTNER_FAILURE"
};

export const LOAD_PARTNERS = {
  BEGIN: "LOAD_PARTNERS_BEGIN",
  SUCCESS: "LOAD_PARTNERS_SUCCESS",
  FAILURE: "LOAD_PARTNERS_FAILURE"
};

export const UPDATE_PARTNER = {
  BEGIN: "UPDATE_PARTNER_BEGIN",
  SUCCESS: "UPDATE_PARTNER_SUCCESS",
  FAILURE: "UPDATE_PARTNER_FAILURE"
};

const initialState = { error: null, message: null, data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PARTNERS.SUCCESS: {
      const reducedData = action.payload.reduce((acc, partner) => {
        acc[partner.id] = partner;
        return acc;
      }, {});

      return { error: null, data: reducedData, message: null };
    }
    case ADD_NEW_PARTNER.SUCCESS: {
      if (action.payload.error) {
        return { data: state.data, error: action.payload.error, message: null };
      }
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.partnerName} is successfully added!`
      };
    }
    case DELETE_PARTNER.SUCCESS: {
      delete state.data[action.payload];
      return {
        data: { ...state.data },
        message: `Successfully deleted!`
      };
    }
    case UPDATE_PARTNER.SUCCESS: {
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.partnerName} is successfully added!`
      };
    }

    case CLEAR_PARTNER_MESSAGES: {
      return { data: state.data, error: null, message: null };
    }

    default:
      return state;
  }
}

export const preparePartners = partners =>
  Object.keys(partners).map(key => ({
    label: partners[key].partnerName,
    value: key,
    notes: partners[key].notes
  }));

export const getPartners = token => {
  return {
    types: [LOAD_PARTNERS.BEGIN, LOAD_PARTNERS.SUCCESS, LOAD_PARTNERS.FAILURE],
    api: api => api.getPartners(token)
  };
};

export const createPartner = ({ name, notes }) => {
  return {
    types: [
      ADD_NEW_PARTNER.BEGIN,
      ADD_NEW_PARTNER.SUCCESS,
      ADD_NEW_PARTNER.FAILURE
    ],
    api: api => api.createPartner({ name, notes })
  };
};

export const clearPartnerErrors = () => {
  return { type: CLEAR_PARTNER_MESSAGES };
};

export const deletePartner = id => {
  return {
    types: [
      DELETE_PARTNER.BEGIN,
      DELETE_PARTNER.SUCCESS,
      DELETE_PARTNER.FAILURE
    ],
    api: api => api.deletePartner(id)
  };
};

export const updatePartner = (id, body) => {
  return {
    types: [
      UPDATE_PARTNER.BEGIN,
      UPDATE_PARTNER.SUCCESS,
      UPDATE_PARTNER.FAILURE
    ],
    api: api => api.updatePartner(id, body)
  };
};
