export const CLEAR_AD_PARAMETER_MESSAGES = "CLEAR_AD_PARAMETER_MESSAGES";
export const CREATE_AD_PARAMETER = {
  BEGIN: "CREATE_AD_PARAMETER_BEGIN",
  SUCCESS: "CREATE_AD_PARAMETER_SUCCESS",
  FAILURE: "CREATE_AD_PARAMETER_FAILURE"
};

const LOAD_AD_PARAMETERS = {
  BEGIN: "LOAD_AD_PARAMETERS_BEGIN",
  SUCCESS: "LOAD_AD_PARAMETERS_SUCCESS",
  FAILURE: "LOAD_AD_PARAMETERS_FAILURE"
};

export const DELETE_AD_PARAMETER = {
  BEGIN: "DELETE_AD_PARAMETER_BEGIN",
  SUCCESS: "DELETE_AD_PARAMETER_SUCCESS",
  FAILURE: "DELETE_AD_PARAMETER_FAILURE"
};

export const UPDATE_AD_PARAMETER = {
  BEGIN: "UPDATE_AD_PARAMETER_BEGIN",
  SUCCESS: "UPDATE_AD_PARAMETER_SUCCESS",
  FAILURE: "UPDATE_AD_PARAMETER_FAILURE"
};

const initialState = { error: {}, message: {}, data: {} };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_AD_PARAMETERS.SUCCESS: {
      const reducedData = action.payload.reduce((acc, adPrams) => {
        acc[adPrams.id] = adPrams;
        return acc;
      }, {});

      return { error: null, data: reducedData, message: null };
    }
    case DELETE_AD_PARAMETER.SUCCESS: {
      delete state.data[action.payload];
      return {
        data: { ...state.data },
        message: `Successfully deleted!`
      };
    }
    case CREATE_AD_PARAMETER.SUCCESS: {
      if (action.payload.error) {
        return { data: state.data, error: action.payload.error, message: null };
      }
      state.data[action.payload.id] = action.payload;
      return {
        data: { ...state.data },
        error: null,
        message: `${action.payload.label}: ${action.payload.parameter}  is successfully added!`
      };
    }

    case UPDATE_AD_PARAMETER.SUCCESS: {
      state.data[action.payload.id] = action.payload;
      return {
        data: { ...state.data },
        error: null,
        message: `${action.payload.label}: ${action.payload.parameter} is successfully added!`
      };
    }
    default:
      return state;
  }
}

export const clearCampaignErrors = () => {
  return { type: CLEAR_AD_PARAMETER_MESSAGES };
};

export const getAdParameters = token => {
  return {
    types: [
      LOAD_AD_PARAMETERS.BEGIN,
      LOAD_AD_PARAMETERS.SUCCESS,
      LOAD_AD_PARAMETERS.FAILURE
    ],
    api: api => api.getAdParameters(token)
  };
};

export const createAdParameter = id => {
  return {
    types: [
      CREATE_AD_PARAMETER.BEGIN,
      CREATE_AD_PARAMETER.SUCCESS,
      CREATE_AD_PARAMETER.FAILURE
    ],
    api: api => api.createAdParameter(id)
  };
};

export const deleteAdParameter = id => {
  return {
    types: [
      DELETE_AD_PARAMETER.BEGIN,
      DELETE_AD_PARAMETER.SUCCESS,
      DELETE_AD_PARAMETER.FAILURE
    ],
    api: api => api.deleteAdParameter(id)
  };
};

export const updateAdParameter = (id, body) => {
  return {
    types: [
      UPDATE_AD_PARAMETER.BEGIN,
      UPDATE_AD_PARAMETER.SUCCESS,
      UPDATE_AD_PARAMETER.FAILURE
    ],
    api: api => api.updateAdParameter(id, body)
  };
};
