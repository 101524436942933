import Publishers from "./components/Publishers";
import Campaigns from "./components/Campaigns";
import Partners from "./components/Partners";
import Pixels from "./components/Pixels";
import AdServers from "./components/AdServers";
import AdParameters from "./components/AdParameters";

const pages = [
  {
    title: "Pixels",
    to: "/pixels",
    path: "/pixels",
    component: Pixels,
    icon: "house"
  },
  {
    title: "Partners",
    to: "/partners",
    path: "/partners",
    component: Partners,
    icon: "users"
  },
  {
    title: "Campaigns",
    to: "/campaigns",
    path: "/campaigns",
    component: Campaigns,
    icon: "companies"
  },
  {
    title: "Publishers",
    to: "/publishers",
    path: "/publishers",
    component: Publishers,
    icon: "applications"
  },
  {
    title: "Ad Servers",
    to: "/ad-servers",
    path: "/ad-servers",
    component: AdServers,
    icon: "applications"
  },
  {
    title: "Ad Parameters",
    to: "/ad-params",
    path: "/ad-params",
    component: AdParameters,
    icon: "applications"
  }
];

export default pages;
