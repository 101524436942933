export const CLEAR_CAMPAIGN_MESSAGES = "CLEAR_CAMPAIGN_MESSAGES";
export const ADD_NEW_CAMPAIGN = {
  BEGIN: "ADD_NEW_CAMPAIGN_BEGIN",
  SUCCESS: "ADD_NEW_CAMPAIGN_SUCCESS",
  FAILURE: "ADD_NEW_CAMPAIGN_FAILURE"
};
export const DELETE_CAMPAIGN = {
  BEGIN: "DELETE_CAMPAIGN_BEGIN",
  SUCCESS: "DELETE_CAMPAIGN_SUCCESS",
  FAILURE: "DELETE_CAMPAIGN_FAILURE"
};

export const LOAD_CAMPAIGNS = {
  BEGIN: "LOAD_CAMPAIGNS_BEGIN",
  SUCCESS: "LOAD_CAMPAIGNS_SUCCESS",
  FAILURE: "LOAD_CAMPAIGNS_FAILURE"
};

export const UPDATE_CAMPAIGN = {
  BEGIN: "UPDATE_CAMPAIGN_BEGIN",
  SUCCESS: "UPDATE_CAMPAIGN_SUCCESS",
  FAILURE: "UPDATE_CAMPAIGN_FAILURE"
};

const initialState = { error: null, message: null, data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CAMPAIGNS.SUCCESS: {
      const reducedData = action.payload.reduce((acc, campaign) => {
        acc[campaign.id] = campaign;
        return acc;
      }, {});

      return { error: null, data: reducedData, message: null };
    }
    case ADD_NEW_CAMPAIGN.SUCCESS: {
      if (action.payload.error) {
        return { data: state.data, error: action.payload.error, message: null };
      }
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.campaignName} is successfully added!`
      };
    }
    case DELETE_CAMPAIGN.SUCCESS: {
      delete state.data[action.payload];
      return {
        data: { ...state.data },
        message: `Successfully deleted!`
      };
    }
    case UPDATE_CAMPAIGN.SUCCESS: {
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.campaignName} is successfully added!`
      };
    }

    case CLEAR_CAMPAIGN_MESSAGES: {
      return { data: state.data, error: null, message: null };
    }

    default:
      return state;
  }
}

export const prepareCampaigns = campaigns =>
  Object.keys(campaigns).map(key => ({
    label: campaigns[key].campaignName,
    value: key
  }));

export const getCampaigns = token => {
  return {
    types: [
      LOAD_CAMPAIGNS.BEGIN,
      LOAD_CAMPAIGNS.SUCCESS,
      LOAD_CAMPAIGNS.FAILURE
    ],
    api: api => api.getCampaigns(token)
  };
};

export const createCampaign = name => {
  return {
    types: [
      ADD_NEW_CAMPAIGN.BEGIN,
      ADD_NEW_CAMPAIGN.SUCCESS,
      ADD_NEW_CAMPAIGN.FAILURE
    ],
    api: api => api.createCampaign({ name })
  };
};

export const clearCampaignErrors = () => {
  return { type: CLEAR_CAMPAIGN_MESSAGES };
};

export const deleteCampaign = id => {
  return {
    types: [
      DELETE_CAMPAIGN.BEGIN,
      DELETE_CAMPAIGN.SUCCESS,
      DELETE_CAMPAIGN.FAILURE
    ],
    api: api => api.deleteCampaign(id)
  };
};

export const updateCampaign = (id, body) => {
  return {
    types: [
      UPDATE_CAMPAIGN.BEGIN,
      UPDATE_CAMPAIGN.SUCCESS,
      UPDATE_CAMPAIGN.FAILURE
    ],
    api: api => api.updateCampaign(id, body)
  };
};
