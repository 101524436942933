import Cookies from "js-cookie";
const getAccessToken = () => {
  return Cookies.get("accessToken");
};

const GET_PARAMS = token => {
  token = token || getAccessToken();
  return {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const POST_PARAMS = body => ({
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`
  },
  body: JSON.stringify(body)
});

const PATCH_PARAMS = body => ({
  method: "PATCH",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`
  },
  body: JSON.stringify(body)
});

const DELETE_PARAMS = {
  method: "DELETE",
  headers: {
    Authorization: `Bearer ${getAccessToken()}`
  }
};

/*CAMPAIGNS*/
export const getCampaigns = token => fetch("/api/campaigns", GET_PARAMS(token));

export const createCampaign = body =>
  fetch("/api/campaigns", POST_PARAMS(body));

export const deleteCampaign = id =>
  fetch(`/api/campaigns/${id}`, DELETE_PARAMS);

export const updateCampaign = (id, body) =>
  fetch(`/api/campaigns/${id}`, PATCH_PARAMS(body));

/*PUBLISHERS*/
export const getPublishers = token =>
  fetch("/api/publishers", GET_PARAMS(token));

export const createPublisher = body =>
  fetch("/api/publishers", POST_PARAMS(body));

export const deletePublisher = id =>
  fetch(`/api/publishers/${id}`, DELETE_PARAMS);

export const updatePublisher = (id, body) =>
  fetch(`/api/publishers/${id}`, PATCH_PARAMS(body));

/*AD PARAMETERS*/
export const getAdParameters = token =>
  fetch("/api/ad-params", GET_PARAMS(token));

export const createAdParameter = body =>
  fetch("/api/ad-params", POST_PARAMS(body));

export const updateAdParameter = (id, body) =>
  fetch(`/api/ad-params/${id}`, PATCH_PARAMS(body));

export const deleteAdParameter = id =>
  fetch(`/api/ad-params/${id}`, DELETE_PARAMS);

/*AD SERVERS*/
export const getAdServers = token =>
  fetch("/api/ad-servers", GET_PARAMS(token));

export const deleteAdServer = id =>
  fetch(`/api/ad-servers/${id}`, DELETE_PARAMS);

export const createAddServer = body =>
  fetch("/api/ad-servers", POST_PARAMS(body));

export const updateAdServer = (id, body) =>
  fetch(`/api/ad-servers/${id}`, PATCH_PARAMS(body));

/*PIXELS*/
export const getPixels = token => fetch("/api/pixels", GET_PARAMS(token));

export const createNewPixel = bodies =>
  fetch("/api/pixels", POST_PARAMS(bodies));

/*partners*/
export const getPartners = token => fetch("/api/partners", GET_PARAMS(token));

export const createPartner = body => fetch("/api/partners", POST_PARAMS(body));

export const deletePartner = id => fetch(`/api/partners/${id}`, DELETE_PARAMS);

export const updatePartner = (id, body) =>
  fetch(`/api/partners/${id}`, PATCH_PARAMS(body));

export const downloadPixel = body =>
  fetch(`/api/pixels/download`, POST_PARAMS(body));
