import React from "react";
import { Box, Flex, Button, Text, Dropdown, Input } from "samba-ui";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";

const StyledBox = styled(Box)`
  width: 250px;
`;
const StyledInput = styled(Input)`
  width: 250px;
  pointer-events: ${p => (p.disabled ? "none" : "auto")};
`;

const CreatePublisherAdServer = ({
  publisher,
  onSelectAdServer,
  createNewPublisher,
  publishers,
  onPublisherSelect,
  setShowNewPubWithAd,
  selectedPublisherWithAds
}) => {
  const showDeleteButton =
    Object.keys(selectedPublisherWithAds).length &&
    !Object.keys(publisher).length;

  return (
    <Box style={{ position: "relative" }} mr={4}>
      <Box mb={2}>
        <Flex alignItems="center" mt={2}>
          <Text mr={"74px"}>Publisher:</Text>
          {publisher && (
            <Box mr={"87px"}>
              <StyledInput disabled value={publisher.notes || ""} />
            </Box>
          )}
          <StyledBox>
            <CreatableSelect
              isClearable
              menuHeight="140px"
              options={publishers}
              value={Object.keys(publisher).length ? publisher : null}
              onChange={onPublisherSelect}
              maxHeight={300}
            />
          </StyledBox>
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mt={2}>
          <Text>Ad Server:</Text>
          <StyledBox mr={"44px"}>
            <Dropdown
              menuHeight="150px"
              options={publisher && publisher.dropdownOptions}
              selected={(publisher && publisher.selectedAdServer) || null}
              onSelect={s => onSelectAdServer(s, { ...publisher })}
              maxHeight={300}
            />
          </StyledBox>
        </Flex>
      </Box>
      <Box
        style={{
          position: "absolute",
          top: showDeleteButton ? "0px" : "18px",
          right: "-22px"
        }}
      >
        <Button
          disabled={!publisher || !publisher.selectedAdServer}
          onClick={createNewPublisher}
        >
          +
        </Button>
        {showDeleteButton ? (
          <Box mt={2}>
            <Button
              variant="destructiveBordered"
              onClick={() => setShowNewPubWithAd(false)}
            >
              x
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default CreatePublisherAdServer;
