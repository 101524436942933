import { omit } from "lodash";

const promiseMiddleware = store => next => action => {
  if (typeof action === "function") {
    return action(store.dispatch, store.getState);
  }

  const { promise, types } = action;
  if (!promise) {
    return next(action);
  }

  const [BEGIN, SUCCESS, FAILURE] = Array.from(types);
  action = omit(action, ["promise", "types"]);

  return new Promise((resolve, reject) => {
    const begin = () => store.dispatch({ ...action, type: BEGIN });

    const success = result => {
      store.dispatch({ ...action, payload: result, type: SUCCESS });

      return resolve(result);
    };

    const fail = error => {
      store.dispatch({ ...action, error, type: FAILURE });
      return reject(error);
    };

    begin();
    return Promise.resolve(promise())
      .then(success)
      .catch(fail);
  });
};

export default promiseMiddleware;
