import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, Button, Modal } from "samba-ui";
import NameInput from "./NameInput";

// TODO connect store here and check for name, if it is already created, show error

const CreateNewCampaignModal = ({ isOpen, onClose, onSubmit }) => {
  const [nameError, setNameError] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const clearState = () => {
    setCampaignName("");
  };
  const disabled = nameError || !campaignName;
  return (
    <Modal width={592} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Create Campaign</Text>
      </Modal.Header>
      <Modal.Content>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Campaign Name</Text>
          <NameInput
            value={campaignName}
            setValue={setCampaignName}
            error={nameError}
            setError={setNameError}
          />
        </Flex>
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button
            disabled={disabled}
            variant="primaryBordered"
            onClick={() => {
              clearState();
              onSubmit(campaignName);
            }}
          >
            Create
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};
CreateNewCampaignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default CreateNewCampaignModal;
