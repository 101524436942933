import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Flex,
  Heading,
  Box,
  List,
  Text,
  Button,
  Input,
  Message,
  Dropdown
} from "samba-ui";
import styled from "styled-components";
import NameInput from "./NameInput";
import {
  getCampaigns,
  createCampaign,
  clearCampaignErrors,
  deleteCampaign,
  updateCampaign
} from "../reducers/campaigns";
import CreateNewCampaignModal from "./CreateNewCampaignModal";
import AreYouSureModal from "./AreYouSureModal";

import getPixelsByKey from "../utils/getPixelsByKey";

const DisabledBox = styled(Box)`
  pointer-events: none;
`;

const Campaigns = ({
  campaigns,
  pixels,
  createCampaign,
  getCampaigns,
  error,
  clearCampaignErrors,
  message,
  deleteCampaign,
  updateCampaign
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [nameError, setNameError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    if (error || message) {
      // clear Error Message
      setTimeout(clearCampaignErrors, 2000);
    }
  }, [clearCampaignErrors, error, message]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  campaigns = Object.values(campaigns).filter(
    c => c.campaignName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );

  const createNewCampaign = name => {
    createCampaign(name);
    setIsOpen(false);
  };

  const onDeleteCampaign = () => {
    deleteCampaign(selectedCampaign.id);
    setIsDeleteModalOpen(false);
  };

  return (
    <Container maxWidth={2048}>
      <Flex alignItems="flex-start">
        <Box width={3 / 4} pr={4} minWidth={960}>
          <Flex mb={3} alignItems="space-between">
            <Heading color="secondary" fontSize={3} fontWeight={500}>
              Campaigns
            </Heading>
          </Flex>
          {(message || error) && (
            <Message mb={3} variant={error ? "alert" : "success"}>
              {message || error}
            </Message>
          )}
          <List.Header borderColor="#d6d6d6 !important">
            <Flex alignItems="center">
              <Box width={2 / 8}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Name
                </Text>
              </Box>
              <Box width={2 / 8}>
                <Text fontSize={2} color="secondary" fontWeight={500}>
                  Pixels
                </Text>
              </Box>
            </Flex>
          </List.Header>
          {!campaigns.length && <List.Empty>List is empty!</List.Empty>}
          {campaigns.map(campaign => {
            const { campaignName: name } = campaign;

            const campaignPixels = getPixelsByKey(
              pixels,
              "campaign.campaign_name",
              name
            );
            const dropdownOptions = Object.keys(campaignPixels).map(name => ({
              label: name,
              value: name
            }));

            return (
              <List.Item key={name} borderColor="#d6d6d6 !important">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box width={2 / 8}>
                    {editMode && selectedCampaign.id === campaign.id ? (
                      <NameInput
                        value={newCampaignName}
                        setValue={setNewCampaignName}
                        error={nameError}
                        setError={setNameError}
                        style={{ width: 200, marginRight: 24 }}
                      />
                    ) : (
                      <Text fontSize={2} fontWeight={400}>
                        {name}
                      </Text>
                    )}
                  </Box>
                  <DisabledBox width={2 / 8}>
                    {dropdownOptions.length ? (
                      <Dropdown selected={dropdownOptions} isMulti />
                    ) : (
                      "-"
                    )}
                  </DisabledBox>
                  <Flex
                    width={4 / 8}
                    alignItems="center"
                    textAlign="right"
                    justifyContent="space-around"
                  >
                    <Box width={5 / 16}>
                      {!(editMode && selectedCampaign.id === campaign.id) ? (
                        <Button
                          variant="primaryBordered"
                          onClick={() => {
                            setNewCampaignName(name);
                            setSelectedCampaign(campaign);
                            setEditMode(true);
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setEditMode(false);
                              setSelectedCampaign({});
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primaryBordered"
                            disabled={nameError}
                            onClick={() => {
                              setEditMode(false);
                              updateCampaign(campaign.id, {
                                name: newCampaignName
                              });
                            }}
                          >
                            Save
                          </Button>
                        </Flex>
                      )}
                    </Box>
                    <Box width={1 / 8}>
                      <Button
                        variant="destructiveBordered"
                        onClick={() => {
                          setSelectedCampaign(campaign);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </List.Item>
            );
          })}
        </Box>
        <Box width={1 / 4} mt={5}>
          <Box mb={3}>
            <Box mb={1}>
              <Text fontWeight={1} fontSize={1} color="palette.ink.light">
                Search Campaign
              </Text>
            </Box>
            <Input
              style={{ width: "100%" }}
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Box>
          <Button width="100%" onClick={() => setIsOpen(!isOpen)}>
            Create New Campaign
          </Button>
        </Box>
      </Flex>
      <CreateNewCampaignModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={createNewCampaign}
      />
      <AreYouSureModal
        title="Delete Campaign"
        content={`Are you sure you want to delete the campaign: ${selectedCampaign.campaignName}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={onDeleteCampaign}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  campaigns: state.campaigns.data,
  pixels: state.pixels.data,
  error: state.campaigns.error,
  message: state.campaigns.message
});

const mapDispatchToProps = dispatch => ({
  getCampaigns: () => dispatch(getCampaigns()),
  deleteCampaign: id => dispatch(deleteCampaign(id)),
  createCampaign: body => dispatch(createCampaign(body)),
  updateCampaign: (id, body) => dispatch(updateCampaign(id, body)),
  clearCampaignErrors: () => dispatch(clearCampaignErrors())
});

Campaigns.propTypes = {
  campaigns: PropTypes.object.isRequired,
  pixels: PropTypes.object.isRequired,
  createCampaign: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  clearCampaignErrors: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);
