import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Flex, Text, Button, Modal, Input } from "samba-ui";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 300px;
`;

const CreateNewParamModal = ({ isOpen, onClose, onSubmit, adParams }) => {
  const [label, setLabel] = useState("");
  const [param, setParam] = useState("");
  // TODO: Add valdiation for param, it should start with sa_ and it can not contain space
  const clearState = () => {
    setLabel("");
    setParam("");
  };

  return (
    <Modal width={592} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Create new pixel url</Text>
      </Modal.Header>
      <Modal.Content>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Label:</Text>
          <StyledInput onChange={e => setLabel(e.target.value)} value={label} />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={2}>
          <Text>Parameter:</Text>
          <StyledInput
            onChange={e => setParam(e.target.value.trim())}
            value={param}
          />
        </Flex>
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button
            variant="primaryBordered"
            onClick={() => {
              clearState();
              onSubmit(label.trim(), param);
            }}
          >
            Generate
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = state => ({
  adParams: state.adParams
});

CreateNewParamModal.propTypes = {
  adParams: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  null
)(CreateNewParamModal);
