export const CLEAR_PUBLISHER_MESSAGES = "CLEAR_PUBLISHER_MESSAGES";
export const ADD_NEW_PUBLISHER = {
  BEGIN: "ADD_NEW_PUBLISHER_BEGIN",
  SUCCESS: "ADD_NEW_PUBLISHER_SUCCESS",
  FAILURE: "ADD_NEW_PUBLISHER_FAILURE"
};
export const DELETE_PUBLISHER = {
  BEGIN: "DELETE_PUBLISHER_BEGIN",
  SUCCESS: "DELETE_PUBLISHER_SUCCESS",
  FAILURE: "DELETE_PUBLISHER_FAILURE"
};

export const LOAD_PUBLISHERS = {
  BEGIN: "LOAD_PUBLISHERS_BEGIN",
  SUCCESS: "LOAD_PUBLISHERS_SUCCESS",
  FAILURE: "LOAD_PUBLISHERS_FAILURE"
};

export const UPDATE_PUBLISHER = {
  BEGIN: "UPDATE_PUBLISHER_BEGIN",
  SUCCESS: "UPDATE_PUBLISHER_SUCCESS",
  FAILURE: "UPDATE_PUBLISHER_FAILURE"
};

const initialState = { error: null, message: null, data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PUBLISHERS.SUCCESS: {
      const reducedData = action.payload.reduce((acc, publisher) => {
        acc[publisher.id] = publisher;
        return acc;
      }, {});

      return { error: null, data: reducedData, message: null };
    }
    case ADD_NEW_PUBLISHER.SUCCESS: {
      if (action.payload.error) {
        return { data: state.data, error: action.payload.error, message: null };
      }
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.publisherName} is successfully added!`
      };
    }
    case DELETE_PUBLISHER.SUCCESS: {
      delete state.data[action.payload];
      return {
        data: { ...state.data },
        message: `Successfully deleted!`
      };
    }
    case UPDATE_PUBLISHER.SUCCESS: {
      state.data[action.payload.id] = action.payload;
      return {
        data: state.data,
        error: null,
        message: `${action.payload.publisherName} is successfully added!`
      };
    }

    case CLEAR_PUBLISHER_MESSAGES: {
      return { data: state.data, error: null, message: null };
    }

    default:
      return state;
  }
}

export const preparePublishers = publishers => {
  return Object.keys(publishers).map(key => {
    const { id, publisherName, notes } = publishers[key];
    return { value: id, label: publisherName, notes };
  });
};

// publishers.map(p => ({ label: p, value: p }));

export const getPublishers = token => {
  return {
    types: [
      LOAD_PUBLISHERS.BEGIN,
      LOAD_PUBLISHERS.SUCCESS,
      LOAD_PUBLISHERS.FAILURE
    ],
    api: api => api.getPublishers(token)
  };
};

export const createPublisher = body => {
  return {
    types: [
      ADD_NEW_PUBLISHER.BEGIN,
      ADD_NEW_PUBLISHER.SUCCESS,
      ADD_NEW_PUBLISHER.FAILURE
    ],
    api: api => api.createPublisher(body)
  };
};

export const clearPublisherErrors = () => {
  return { type: CLEAR_PUBLISHER_MESSAGES };
};

export const deletePublisher = id => {
  return {
    types: [
      DELETE_PUBLISHER.BEGIN,
      DELETE_PUBLISHER.SUCCESS,
      DELETE_PUBLISHER.FAILURE
    ],
    api: api => api.deletePublisher(id)
  };
};

export const updatePublisher = (id, body) => {
  return {
    types: [
      UPDATE_PUBLISHER.BEGIN,
      UPDATE_PUBLISHER.SUCCESS,
      UPDATE_PUBLISHER.FAILURE
    ],
    api: api => api.updatePublisher(id, body)
  };
};
