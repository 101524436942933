const LOAD_PIXELS = {
  BEGIN: "LOAD_PIXELS_BEGIN",
  SUCCESS: "LOAD_PIXELS_SUCCESS",
  FAILURE: "LOAD_PIXELS_FAILURE"
};

const ADD_NEW_PIXEL = {
  BEGIN: "ADD_NEW_PIXEL_BEGIN",
  SUCCESS: "ADD_NEW_PIXEL_SUCCESS",
  FAILURE: "ADD_NEW_PIXEL_FAILURE"
};

const initialState = { data: {}, message: null, error: null };

const sortByDate = data =>
  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PIXELS.SUCCESS: {
      const data = sortByDate(action.payload).reduce((acc, pixel) => {
        acc[pixel.id] = pixel;
        return acc;
      }, {});
      return { data };
    }
    case ADD_NEW_PIXEL.SUCCESS: {
      const pixels = action.payload;
      pixels.forEach(pixel => {
        state.data[pixel.id] = pixel;
        state.data = sortByDate(Object.values(state.data)).reduce(
          (acc, pixel) => {
            acc[pixel.id] = pixel;
            return acc;
          },
          {}
        );
      });

      return { data: { ...state.data } };
    }
    default:
      return state;
  }
}

export const createNewPixel = body => {
  return {
    types: [ADD_NEW_PIXEL.BEGIN, ADD_NEW_PIXEL.SUCCESS, ADD_NEW_PIXEL.FAILURE],
    api: api => api.createNewPixel(body)
  };
};

export const getPixels = token => {
  return {
    types: [LOAD_PIXELS.BEGIN, LOAD_PIXELS.SUCCESS, LOAD_PIXELS.FAILURE],
    api: api => api.getPixels(token)
  };
};
