import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Box, SidebarItem } from "samba-ui";
import useActiveRoute from "./hooks/useActiveRoute";

const Pages = ({ pages }) => {
  const [activeRoute, isActive] = useActiveRoute("/");

  pages = pages.filter(page => page.to);

  return (
    <Box>
      {pages.map(({ to, title, icon }) => (
        <NavLink
          style={{ textDecoration: "none" }}
          key={title}
          exact
          isActive={isActive.bind(null, to)}
          to={to}
        >
          <SidebarItem
            icon={icon}
            selected={activeRoute === to}
            title={title}
          />
        </NavLink>
      ))}
    </Box>
  );
};

Pages.propTypes = {
  pages: PropTypes.array
};

export default Pages;
