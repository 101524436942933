import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Heading, Image, Text, Button, Flex, Container } from "samba-ui";
import styled from "styled-components";

import ModernFamily from "./assets/modern_family.jpg";
import Logo from "./assets/logo_red_small.png";

const Background = styled(Box)`
  background: url(${ModernFamily});
  transition: opacity 2s ease-in-out;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  opacity: 1;
`;

const StyledFlex = styled(Flex)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const Mask = styled(Box)`
  background-image: linear-gradient(219deg, rgba(0, 0, 0, 0), #000);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`;

const UnauthorizedApp = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container maxWidth={2048}>
      <Background />
      <Mask />

      <Box>
        <Flex alignItems="center">
          <Image width={37} height={37} src={Logo} />

          <Text ml={3} color="white" fontSize={3}>
            Pixel Manager
          </Text>
        </Flex>
      </Box>

      <StyledFlex mt={200}>
        <Box>
          <Heading color="white" fontSize={58}>
            TV Pixel Manager
          </Heading>
          <Text color="white" fontSize={4}>
            Samba TV helps better inform marketers on their media measurement
            and analytics by providing the fastest availability of TV data in
            the market
          </Text>
          <Box mt={3} width={200}>
            <Button style={{ width: "100%" }} onClick={loginWithRedirect}>
              Sign in
            </Button>
          </Box>
        </Box>
      </StyledFlex>
    </Container>
  );
};

export default UnauthorizedApp;
