import React from "react";
import { Button, Flex, Text, Modal, Box } from "samba-ui";

const PixelGenerationWarningModal = ({ data, isOpen, onClose, onSubmit }) => {
  return (
    <Modal width={400} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Please confirm the created options</Text>
      </Modal.Header>
      <Modal.Content>
        <Box mb={2}>
          <Text fontSize={2} fontWeight={400}>
            By submitting this you will be creating the followings
          </Text>
        </Box>
        {Object.keys(data).map(key => {
          return (
            <Flex key={key} alignItems="center">
              <Box mr={2}>
                <Text fontSize={2} fontWeight={500}>
                  {key.toUpperCase()}:
                </Text>
              </Box>
              <Text>{data[key]}</Text>
            </Flex>
          );
        })}
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button variant="primaryBordered" onClick={onSubmit}>
            Create & Generate
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

export default PixelGenerationWarningModal;
