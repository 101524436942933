import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, Button, Modal, Input, Dropdown } from "samba-ui";
import styled from "styled-components";
import NameInput from "./NameInput";
const StyledInput = styled(Input)`
  width: 300px;
`;

const CreateAdServerModal = ({
  isOpen,
  onClose,
  onSubmit,
  adParams,
  adServer,
  updateMode
}) => {
  const [adName, setAdName] = useState("");
  const [nameError, setNameError] = useState("");
  const [params, setParams] = useState([]);
  const [queryParamValues, setQueryParamValues] = useState([]);

  useEffect(() => {
    if (updateMode) {
      setAdName(adServer.name);
      setParams(
        adServer.newParams.map(({ id, param: { label } }) => ({
          label,
          value: id
        }))
      );
      const queryParamState = adServer.dropdownOptions.map(
        ({ parameter, value }) => ({ [parameter]: value })
      );
      setQueryParamValues(queryParamState);
    }
  }, [updateMode, adServer, adParams, setAdName]);

  const clearState = () => {
    setAdName("");
    setParams([]);
    setQueryParamValues([]);
  };

  const onCreateClick = () => {
    clearState();
    const result = params.map(({ label, value }, index) => {
      const k = Object.keys(queryParamValues[index])[0];
      return { [value]: queryParamValues[index][k] };
    });
    onSubmit({ adName, params: result });
  };

  const onParamsSelect = selected => {
    if (!selected) {
      selected = [];
    }
    // selected is an array
    setParams(selected);

    const queryParamState = selected.map(({ label, value }) => {
      // value is id, find it in adParams
      const { parameter } = adParams[value];
      const res = { [parameter]: "" };
      // find if the parameter in the queryParamValues
      const found = queryParamValues.find(x => Object.keys(x)[0] === parameter);
      res[parameter] = found ? found[parameter] : "";
      // check queryParamValues here, if the label is in then keep its input value
      return res;
    });

    setQueryParamValues(queryParamState);
  };

  const onParamsChange = (value, key, index) => {
    if (queryParamValues) {
      const newArray = Array.from(queryParamValues);
      newArray[index][key] = value;
      setQueryParamValues(newArray);
    }
  };

  const disabled =
    nameError ||
    !params ||
    !params.length ||
    !adName ||
    !queryParamValues.length ||
    !Object.values(queryParamValues[0])[0].length;

  return (
    <Modal width={592} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Create Ad Server with Parameters</Text>
      </Modal.Header>
      <Modal.Content>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Ad Name</Text>
          <NameInput
            value={adName}
            setValue={setAdName}
            error={nameError}
            setError={setNameError}
          />
        </Flex>
        <Box width={"100%"} mt={3}>
          <Box mb={1}>
            <Text>Set Parameters</Text>
          </Box>
          <Dropdown
            isMulti
            menuHeight="200px"
            options={
              Object.keys(adParams).length
                ? Object.keys(adParams).map(key => ({
                    label: adParams[key].label,
                    value: key
                  }))
                : null
            }
            selected={params}
            onSelect={onParamsSelect}
          />
        </Box>
        <Box my={3}>
          {queryParamValues.map((p, index) => {
            const key = Object.keys(p)[0];
            const value = p[key];
            return (
              <Flex
                key={`${key}+${index}`}
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Text>{key}</Text>
                <StyledInput
                  onChange={e =>
                    onParamsChange(e.target.value.trim(), key, index)
                  }
                  value={value}
                />
              </Flex>
            );
          })}
        </Box>
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button
            variant="primaryBordered"
            onClick={onCreateClick}
            disabled={disabled}
          >
            {updateMode ? "Update" : "Create"}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

CreateAdServerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  adParams: PropTypes.object.isRequired,
  adServer: PropTypes.object.isRequired,
  updateMode: PropTypes.bool.isRequired
};

export default CreateAdServerModal;
