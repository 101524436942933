import { useEffect } from "react";

export default function useWindowEvent(eventName, handler) {
  useEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler]);
}
