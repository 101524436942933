import React from "react";
import { Loader, Flex } from "samba-ui";

const PageLoader = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    height="calc(100vh - 72px)"
    width="100%"
  >
    <Loader />
  </Flex>
);

export default PageLoader;
