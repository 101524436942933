import { combineReducers } from "redux";
import me from "./me";
import adServers from "./adServers";
import adParams from "./adParams";
import pixels from "./pixels";
import campaigns from "./campaigns";
import partners from "./partners";
import publishers from "./publishers";

export const reducers = {
  me,
  adServers,
  adParams,
  pixels,
  campaigns,
  partners,
  publishers
};

export default combineReducers(reducers);
