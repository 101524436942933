import PropTypes from "prop-types";
import styled from "styled-components";
import { Box } from "samba-ui";

const ContentArea = styled(Box)`
  height: 100%;
  margin-left: 56px;
`;

ContentArea.propTypes = {
  fullScreen: PropTypes.bool
};

ContentArea.defaultProps = {
  fullScreen: false
};

export default ContentArea;
