import React from "react";

export const PreviewIcon = ({
  className = "",
  height = 16,
  width = 24,
  fill = "#5d69e6",
  ...props
}) => (
  <svg
    className={className}
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 16"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="View-File" fill={fill}>
        <path
          d="M12,0 C4.94189395,0 0,8 0,8 C0,8 3.59986448,16 12,16 C20.4702694,16 24,8 24,8 C24,8 19.1295951,2.48029579e-15 12,0 Z M12,13.0901475 C9.24716246,13.0901475 7.05946129,10.8361986 7.05946129,8 C7.05946129,5.16380138 9.24716246,2.90985252 12,2.90985252 C14.7528375,2.90985252 16.9405387,5.16380138 16.9405387,8 C16.9405387,10.8361986 14.7528375,13.0901475 12,13.0901475 L12,13.0901475 Z"
          id="Shape"
          fillRule="nonzero"
        />
        <path
          d="M13.6,8.4 C13.6,9.504992 12.704992,10.4 11.6,10.4 C10.495008,10.4 9.6,9.504992 9.6,8.4 C9.6,7.295008 10.495008,6.4 11.6,6.4 C12.704992,6.4 13.6,7.295008 13.6,8.4"
          id="Path"
        />
      </g>
    </g>
  </svg>
);
