const getQueryString = adServer => {
  let { dropdownOptions } = adServer;

  const cachebuster = dropdownOptions.filter(
    ({ label }) => label === "Cachebuster"
  );

  let index;
  if (cachebuster.length) {
    // remove cachebuster from dropdownOptions
    index = dropdownOptions.findIndex(d => d.label === "Cachebuster");
    dropdownOptions.splice(index, 1);
    dropdownOptions.unshift(cachebuster[0]);
  }
  const deviceId = dropdownOptions.filter(
    ({ label }) => label === "Device Advertising ID"
  );
  if (deviceId.length) {
    // remove deviceId from dropdownOptions
    index = dropdownOptions.findIndex(d => d.label === "Device Advertising ID");
    dropdownOptions.splice(index, 1);
    dropdownOptions.push(deviceId[0]);
  }

  const data = dropdownOptions.reduce((acc, { value, parameter }) => {
    acc.push(`${parameter}=${value}`);
    return acc;
  }, []);

  return data.join("&");
};

export default getQueryString;
