import React from "react";
import PropTypes from "prop-types";

import { Button, Box, Modal, Text, Flex } from "samba-ui";

const AreYouSureModal = ({ isOpen, onClose, title, content, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>{title}</Text>
      </Modal.Header>
      <Modal.Content>
        <Box>
          <Text>{content}</Text>
        </Box>
      </Modal.Content>

      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button variant="primaryBordered" onClick={onSubmit}>
            Yes
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

AreYouSureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default AreYouSureModal;
