import _ from "lodash";

const getPixelsByKey = (pixels, key, compare) => {
  pixels = Object.keys(pixels).reduce((acc, name) => {
    if (_.get(pixels[name], key).toLowerCase() === compare.toLowerCase()) {
      acc[name] = pixels[name];
    }
    return acc;
  }, {});

  // group by campaign name
  pixels = Object.keys(pixels)
    .sort((a, b) =>
      pixels[a].campaign.campaign_name.localeCompare(
        pixels[b].campaign.campaign_name
      )
    )
    .reduce((acc, name) => {
      acc[name] = pixels[name];
      return acc;
    }, {});

  return pixels;
};

export default getPixelsByKey;
