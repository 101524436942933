import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, Button, Modal } from "samba-ui";
import styled from "styled-components";
import NameInput from "./NameInput";

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
  border-radius: 4px;
  border-color: rgba(207, 214, 230, 1);
  width: 290px;
`;

const CreateNewPartnerModal = ({ isOpen, onClose, onSubmit }) => {
  const [partnerName, setPartnerName] = useState("");
  const [nameError, setNameError] = useState("");
  const [notes, setNotes] = useState("");

  const clearState = () => {
    setPartnerName("");
    setNotes("");
  };

  const disabled = nameError || !partnerName;
  return (
    <Modal width={592} isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header borderColor="#d6d6d6 !important">
        <Text>Create Partner</Text>
      </Modal.Header>
      <Modal.Content>
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Text>Partner Name</Text>
          <NameInput
            value={partnerName}
            setValue={setPartnerName}
            error={nameError}
            setError={setNameError}
          />
        </Flex>

        <Flex alignItems="center" justifyContent="space-between">
          <Text>Notes</Text>
          <StyledTextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </Flex>
      </Modal.Content>
      <Modal.Actions justifyContent="flex-end" borderColor="#d6d6d6 !important">
        <Flex>
          <Box mr={3}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button
            variant="primaryBordered"
            disabled={disabled}
            onClick={() => {
              clearState();
              onSubmit(partnerName.trim(), notes.trim());
            }}
          >
            Create
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};
CreateNewPartnerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default CreateNewPartnerModal;
