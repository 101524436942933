import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import PageLoader from "./components/PageLoader";
import Sidebar from "./components/Sidebar";
import ContentArea from "./components/ContentArea";
import UnauthorizedApp from "./UnauthorizedApp";

import { getAdParameters } from "./reducers/adParams";
import { getAdServers } from "./reducers/adServers";
import { getCampaigns } from "./reducers/campaigns";
import { getPublishers } from "./reducers/publishers";
import { getPartners } from "./reducers/partners";
import { getPixels } from "./reducers/pixels";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import pages from "./pages";

const App = ({
  getPartners,
  getAdParameters,
  getAdServers,
  getCampaigns,
  getPublishers,
  getPixels
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [cookiesSet, setCookiesSet] = useState(false);

  useEffect(() => {
    const req = async () => {
      const token = await getAccessTokenSilently({
        scope: "openid profile",
        audience: "https://api.platform.samba.tv"
      });
      if (token) {
        Cookies.set("accessToken", token);
        setCookiesSet(true);
        setTimeout(() => {
          // pass token for iniitial request
          getPixels(token);
          getPartners(token);
          getAdParameters(token);
          getAdServers(token);
          getCampaigns(token);
          getPublishers(token);
        }, 100);
      }
    };
    req();
  }, [
    getAccessTokenSilently,
    getAdParameters,
    getAdServers,
    getCampaigns,
    getPartners,
    getPixels,
    getPublishers
  ]);

  if (!cookiesSet) {
    return <PageLoader />;
  }
  if (!isAuthenticated) {
    return <UnauthorizedApp />;
  }
  return (
    <Router>
      <Sidebar pages={pages} />
      <ContentArea>
        <Switch>
          {pages.map(({ path, component: PageComponent }) => (
            <Route
              key={path}
              exact
              path={path}
              render={props => <PageComponent {...props} />}
            />
          ))}
          <Route component={() => <Redirect to={pages[0].to} />} />
        </Switch>
      </ContentArea>
    </Router>
  );
};

App.propTypes = {
  getAdParameters: PropTypes.func.isRequired,
  getPixels: PropTypes.func.isRequired,
  getPublishers: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  getAdServers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  getAdParameters: token => dispatch(getAdParameters(token)),
  getPublishers: token => dispatch(getPublishers(token)),
  getCampaigns: token => dispatch(getCampaigns(token)),
  getAdServers: token => dispatch(getAdServers(token)),
  getPartners: token => dispatch(getPartners(token)),
  getPixels: token => dispatch(getPixels(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
